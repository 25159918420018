import { Component, Output, EventEmitter } from '@angular/core';

import { Language } from 'src/app/core/models';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {

  public isOpen = false;

  @Output()
  languageChange: EventEmitter<Language> = new EventEmitter();

  @Output()
  logout: EventEmitter<null> = new EventEmitter();

  constructor() { }

  public handleBurgerClick(): void {
    this.isOpen = !this.isOpen;
  }

  public handleMenuClose(): void {
    this.isOpen = false;
  }

  public handleLanguageChange(selectedLanguage: Language): void {
    this.languageChange.emit(selectedLanguage);
  }

  public handleLogoutClick(): void {
    this.logout.emit();
  }

}
