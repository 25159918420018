import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

/**
 * Email service - Service to send email validation
 * TODO this service should be used by an authenticated user only
 */
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private apiService: ApiService) { }

  /**
   * GET /auth/email/send/{email}
   */
  sendEmailValidation(email: string): Observable<any> {
    return this.apiService.get('/auth/email/send/' + email);
  }

}
