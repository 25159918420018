import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnInit
} from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { BankCardBrandTypes } from 'src/app/core/models';
import { TranslateService } from '@ngx-translate/core';
import { stripeElements } from 'src/libs/stripe';


@Component({
  selector: 'app-bank-card-form',
  templateUrl: './bank-card-form.component.html',
  styleUrls: ['./bank-card-form.component.scss']
})
export class BankCardFormComponent implements OnInit, AfterViewInit, OnDestroy {

  brand$: BehaviorSubject<BankCardBrandTypes> = new BehaviorSubject<BankCardBrandTypes>(BankCardBrandTypes.VISA);


  @ViewChild('cardNumber', { static: true })
  cardNumberRef: ElementRef;
  cardNumber: any;
  cardNumberHandler = this.onCardNumberChange.bind(this);
  cardNumberError: string;
  cardNumberHasError$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  @ViewChild('cardExpiry', { static: true })
  cardExpiryRef: ElementRef;
  cardExpiry: any;
  cardExpiryHander = this.onCardExpiryChange.bind(this);
  cardExpiryError: string;
  cardExpiryHasError$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  @ViewChild('cardCvc', { static: true })
  cardCvcRef: ElementRef;
  cardCvc: any;
  cardCvcHander = this.onCardCvcChange.bind(this);
  cardCvcError: string;
  cardCvcHasError$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  @Input()
  disabled: boolean;

  @Input()
  cardData: any;

  @Output()
  cardChange: EventEmitter<any> = new EventEmitter();

  @Output()
  cardIsValid: EventEmitter<boolean> = new EventEmitter();

  private style = {
    base: {
      color: 'white',
      textAlign: 'left',
      '::placeholder': {
        color: 'white'
      }
    }
  };

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    if (this.cardData) {
      this.brand$.next(this.cardData.brand.toLowerCase());
    }
    combineLatest(
      this.cardNumberHasError$,
      this.cardExpiryHasError$,
      this.cardCvcHasError$
    ).subscribe(([cardNumberHasError, cardExpiryHasError, cardCvcHasError]) => {
      if (!cardNumberHasError && !cardExpiryHasError && !cardCvcHasError) {
        this.cardIsValid.emit(true);
      } else {
        this.cardIsValid.emit(false);
      }
    });
  }

  async ngAfterViewInit() {
    await this.createElements();
    this.moutElements();
  }

  ngOnDestroy() {
    this.destroyElements();
  }

  public onCardNumberChange(event): void {
    this.brand$.next(event.brand);
    if (event.complete) {
      this.cardNumberError = null;
      this.cardNumberHasError$.next(false);
    } else {
      if (event.error) {
        this.cardNumberError = event.error.message;
      } else if (event.empty) {
        this.cardNumberError = 'empty';
      }
      this.cardNumberHasError$.next(true);
    }
    // just need the cardNumber for stripe setup intent
    this.cardChange.emit(this.cardNumber);
  }

  onCardExpiryChange(event) {
    if (event.complete) {
      this.cardExpiryError = null;
      this.cardExpiryHasError$.next(false);
    } else {
      if (event.error) {
        this.cardExpiryError = event.error.message;
      } else if (event.empty) {
        this.cardExpiryError = 'empty';
      }
      this.cardExpiryHasError$.next(true);
    }
  }

  onCardCvcChange(event) {
    if (event.complete) {
      this.cardCvcError = null;
      this.cardCvcHasError$.next(false);
    } else {
      if (event.error) {
        this.cardCvcError = event.error.message;
      } else if (event.empty) {
        this.cardCvcError = 'empty';
      }
      this.cardCvcHasError$.next(true);
    }
  }

  private async createElements() {
    this.cardNumber = stripeElements.create('cardNumber', {
      classes: { base: 'card-number' },
      style: this.style,
      placeholder: this.cardData
        ? 'XXXX XXXX XXXX ' + this.cardData.last4
        : await this.translateService.get('REGISTER.PAGE.BANK.FORM.CARD_NUMBER').toPromise(),
      disabled: this.disabled
    });
    this.cardExpiry = stripeElements.create('cardExpiry', {
      classes: { base: 'card-expiry' },
      style: this.style,
      placeholder: this.cardData
        ? this.cardData.expMonth < 10
          ? '0' + this.cardData.expMonth + ' / ' + String(this.cardData.expYear).slice(2)
          : this.cardData.expMonth + ' / ' + String(this.cardData.expYear).slice(2)
        : await this.translateService.get('REGISTER.PAGE.BANK.FORM.CARD_EXP').toPromise(),
      disabled: this.disabled
    });
    this.cardCvc = stripeElements.create('cardCvc', {
      classes: { base: 'card-cvc' },
      style: this.style,
      placeholder: this.cardData
        ? 'XXX'
        : await this.translateService.get('REGISTER.PAGE.BANK.FORM.CARD_CVC').toPromise(),
      disabled: this.disabled
    });
  }

  private moutElements() {
    this.cardNumber.mount(this.cardNumberRef.nativeElement);
    this.cardNumber.addEventListener('change', this.cardNumberHandler);
    this.cardExpiry.mount(this.cardExpiryRef.nativeElement);
    this.cardExpiry.addEventListener('change', this.cardExpiryHander);
    this.cardCvc.mount(this.cardCvcRef.nativeElement);
    this.cardCvc.addEventListener('change', this.cardCvcHander);
  }

  private destroyElements() {
    this.cardNumber.removeEventListener('change', this.cardNumberHandler);
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }
}
