import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Group } from '../models';
import { Store } from '@ngxs/store';
import {  GetGroupRequestAction } from '../store/group/group.actions';

/**
 * Group resolver - Load group from url then redirect to home
 */
@Injectable()
export class GroupResolver implements Resolve<Group> {

  constructor(
    private store$: Store,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Group> {
    return this.store$.dispatch(new GetGroupRequestAction(route.params.groupUUID)).pipe(
      tap(() => this.router.navigate(['']))
    );
  }
}
