import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubscriptionPlan} from '../../../core/models/subscription-plan';

@Component({
  selector: 'app-subscription-plan-grid',
  templateUrl: './subscription-plan-grid.component.html',
  styleUrls: ['./subscription-plan-grid.component.scss']
})
export class SubscriptionPlanGridComponent implements OnInit {

  @Input()
  subscriptionPlans: SubscriptionPlan[];

  @Output() select: EventEmitter<SubscriptionPlan> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick(subscriptionPlan: SubscriptionPlan) {
    this.select.emit(subscriptionPlan);
  }
}
