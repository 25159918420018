import { Pipe, PipeTransform } from '@angular/core';

enum ParamTypes {
  MINUTE = 'minute',
  SECOND = 'second'
}

@Pipe({
  name: 'toHour'
})
export class ToHourPipe implements PipeTransform {

  transform(time: number, args?: ParamTypes): any {
    switch (args) {
      case ParamTypes.SECOND:
        const secondsToHour = this.secondsToHour(time);
        return this.formatHour(secondsToHour);
      case ParamTypes.MINUTE:
        const minutesToHour = this.secondsToHour(this.minutesToSeconds(time));
        return this.formatHour(minutesToHour);
      default:
          const defaultToHour = this.secondsToHour(time);
          return this.formatHour(defaultToHour);
    }
  }

  private secondsToHour(seconds: number): { hours: number, minutes: number, seconds: number } {
    return {
      hours: Math.floor(seconds / 3600),
      minutes: Math.floor(seconds % 3600 / 60),
      seconds: Math.floor(seconds % 3600 % 60)
    };
  }

  private minutesToSeconds(minutes: number): number {
    return minutes * 60;
  }

  private formatHour(hour: { hours: number, minutes: number, seconds: number }) {
    return `${hour.hours}h${
      hour.minutes !== 0
        ? `:${hour.minutes < 10 ? '0' + hour.minutes : hour.minutes}`
        : ''
    }`;
  }

}
