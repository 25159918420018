import { Credentials, User, Language, Group, SubscriptionPlan } from '../../models';

export enum AuthActionTypes {
    AUTHENTICATION_REQUEST = '[Auth] Authentication request',
    AUTHENTICATED = '[Auth] Authenticated',
    NOT_AUTHENTICATED = '[Auth] Not authenticated',

    LOGIN_REQUEST = '[Auth] Login request',
    LOGIN_SUCCESS = '[Auth] Login success',
    LOGIN_ERROR = '[Auth] Login error',

    LOGOUT_REQUEST = '[Auth] Logout request',
    LOGOUT_SUCCESS = '[Auth} Logout success',
    LOGOUT_ERROR = '[Auth] Logout error',

    RESET_PASSWORD_REQUEST = '[Auth] Reset password request',
    RESET_PASSWORD_SUCCESS = '[Auth] Reset password success',
    RESET_PASSWORD_ERROR = '[Auth] Reset password error',

    SEND_VALIDATION_EMAIL_REQUEST = '[Auth] Send validation email request',
    SEND_VALIDATION_EMAIL_SUCCESS = '[Auth] Send validation email success',
    SEND_VALIDATION_EMAIL_ERROR = '[Auth] Send validation email error',

    GET_CARD_WALLET_REQUEST = '[Register] Get card wallet request',
    GET_CARD_WALLET_SUCCESS = '[Register] Get card wallet success',
    GET_CARD_WALLET_ERROR = '[Register] Get card wallet error',

    CREATE_CONTACT_REQUEST = '[Auth] Create contact request',
    CREATE_CONTACT_SUCCESS = '[Auth] Create contact success',
    CREATE_CONTACT_ERROR = '[Auth] Create contact error',

    CHANGE_LANGUAGE = '[Auth] Change language',

    UPDATE_USER = '[Auth] Update user',

    CHANGE_THEME = '[Auth] Change theme'
}

export class AuthenticationRequestAction {
    static readonly type = AuthActionTypes.AUTHENTICATION_REQUEST;
}

export class AuthenticatedAction {
    static readonly type = AuthActionTypes.AUTHENTICATED;
    constructor(public user: User) { }
}

export class NotAuthenticatedAction {
    static readonly type = AuthActionTypes.NOT_AUTHENTICATED;
}

export class LoginRequestAction {
    static readonly type = AuthActionTypes.LOGIN_REQUEST;
    constructor(public credentials: Credentials) { }
}

export class LoginSuccessAction {
    static readonly type = AuthActionTypes.LOGIN_SUCCESS;
    constructor(public user: User) { }
}

export class LoginErrorAction {
    static readonly type = AuthActionTypes.LOGIN_ERROR;
    constructor(public error: any) { }
}

export class LogoutRequestAction {
    static readonly type = AuthActionTypes.LOGOUT_REQUEST;
}

export class LogoutSuccessAction {
    static readonly type = AuthActionTypes.LOGOUT_SUCCESS;
}

export class LogoutErrorAction {
    static readonly type = AuthActionTypes.LOGOUT_ERROR;
    constructor(public error: string) { }
}

export class ResetPasswordRequestAction {
    static readonly type = AuthActionTypes.RESET_PASSWORD_REQUEST;
    constructor(public email: string) { }
}

export class ResetPasswordSuccessAction {
    static readonly type = AuthActionTypes.RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordErrorAction {
    static readonly type = AuthActionTypes.RESET_PASSWORD_ERROR;
    constructor(public error: string) { }
}

export class SendValidationEmailRequestAction {
    static readonly type = AuthActionTypes.SEND_VALIDATION_EMAIL_REQUEST;
    constructor(public email: string) { }
}

export class SendValidationEmailSuccessAction {
    static readonly type = AuthActionTypes.SEND_VALIDATION_EMAIL_REQUEST;
}

export class SendValidationEmailErrorAction {
    static readonly type = AuthActionTypes.SEND_VALIDATION_EMAIL_REQUEST;
    constructor(public error: string) { }
}

export class GetCardWalletRequestAction {
    static readonly type = AuthActionTypes.GET_CARD_WALLET_REQUEST;
}

export class GetCardWalletSuccessAction {
    static readonly type = AuthActionTypes.GET_CARD_WALLET_SUCCESS;
    constructor(public intent: SetupIntent) { }
}

export class GetCardWalletErrorAction {
    static readonly type = AuthActionTypes.GET_CARD_WALLET_ERROR;
    constructor(public error: string) { }
}

export class CreateContactRequestAction {
    static readonly type = AuthActionTypes.CREATE_CONTACT_REQUEST;
    constructor(public message: string) { }
}


export class CreateContactSuccessAction {
    static readonly type = AuthActionTypes.CREATE_CONTACT_SUCCESS;
}

export class CreateContactErrorAction {
    static readonly type = AuthActionTypes.CREATE_CONTACT_ERROR;
    constructor(public error: string) { }
}
export class ChangeLanguageAction {
    static readonly type = AuthActionTypes.CHANGE_LANGUAGE;
    constructor(public language: Language) { }
}


export class UpdateUserAction {
    static readonly type = AuthActionTypes.UPDATE_USER;
    constructor(public user: Partial<User>) { }
}

export class ChangeThemeAction {
    static readonly type = AuthActionTypes.CHANGE_THEME;
    constructor(public themeName: string) { }
}
