import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models';

/**
 * Language service - Language management in app (i18n)
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translateService: TranslateService) { }

  setLanguage(language: Language) {
    this.translateService.setDefaultLang(language.toLowerCase());
  }

}
