import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SubscriptionPlan } from '../models';

import { ApiService } from './api.service';

/**
 * Subscription plan service - Manage Subscription plan
 */
@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {

  private baseUrl = '/auth/subscription-plan/';

  constructor(private apiService: ApiService) { }
  /**
   * GET /auth/subscription-plan/:group_id
   */
  getSubscriptionPlansByGroupId(groupId: number): Observable<SubscriptionPlan[]> {
    return this.apiService.get(this.baseUrl + groupId).pipe(
      catchError(err => throwError(err))
    );
  }
}
