
export enum BankCardBrandTypes {
    MASTERCARD = 'mastercard',
    VISA = 'visa',
    AMEX = 'amex',
    DISCOVER = 'discover',
    DINERS = 'diners',
    JCB = 'jcb',
    UNIONPAY = 'unionpay',
    UNKNOWN = 'unknown',
}
