import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { AuthState, AuthStateModel } from './auth';
import { GroupState, GroupStateModel } from './group';


/**
 * App store selectors - Combine multime selector here to manage global state
 */
@Injectable({ providedIn: 'root' })
export class StoreSelectors {

    @Selector([AuthState, GroupState])
    static appIsLoaded(authState: AuthStateModel, groupState: GroupStateModel): boolean {
        return authState.isLoaded && groupState.isLoaded;
    }

    @Selector([AuthState, GroupState])
    static isAuthenticatedAndIsInGroup(authState: AuthStateModel, groupState: GroupStateModel): [boolean, boolean] {
        return [
            authState.isAuthenticated,
            !!groupState.selected
        ];
    }
}
