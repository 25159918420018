import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centsToEuros'
})
export class CentsToEurosPipe implements PipeTransform {

  transform(cents: number): any {
    return cents / 100;
  }

}
