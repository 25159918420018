import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, switchMap } from 'rxjs/operators';

import { Group } from '../../models';
import { GroupService } from '../../http';
import {
    LoadGroupRequestAction,
    LoadGroupErrorAction,
    LoadGroupSuccessAction,
    GetGroupRequestAction,
    GetGroupSuccessAction,
    GetGroupErrorAction
} from './group.actions';

import { ChangeThemeAction } from '../auth';

export interface GroupStateModel {
    selected: Group | null;
    isLoading: boolean;
    isLoaded?: boolean;
    error?: string;
}

export const groupDefaultState: GroupStateModel = {
    selected: null,
    isLoading: false,
    isLoaded: false
};

/**
 * Group state - Current state of application selected group
 */
@State<GroupStateModel>({
    name: 'group',
    defaults: groupDefaultState
})
export class GroupState {

    constructor(
        private groupService: GroupService
    ) { }

    /**
     * Select selected group
     */
    @Selector()
    static selected(state: GroupStateModel): Group {
        return state.selected;
    }
    /**
     * Select group is loaded
     */
    @Selector()
    static isLoaded(state: GroupStateModel): boolean {
        return state.isLoaded;
    }
    /**
     * Load group request
     */
    @Action(LoadGroupRequestAction)
    loadGroupRequest(ctx: StateContext<GroupStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.groupService.loadGroup().pipe(
            switchMap((group: Group) => ctx.dispatch(new LoadGroupSuccessAction(group))),
            catchError(err => ctx.dispatch(new LoadGroupErrorAction(err.message)))
        );
    }
    /**
     * Load group success
     */
    @Action(LoadGroupSuccessAction)
    loadGroupSuccess(ctx: StateContext<GroupStateModel>, action: LoadGroupSuccessAction) {
        return ctx.dispatch(new GetGroupRequestAction(action.group.groupId));
    }
    /**
     * Load group error
     */
    @Action(LoadGroupErrorAction)
    loadGroupError(ctx: StateContext<GroupStateModel>, action: LoadGroupErrorAction) {
        ctx.setState({
            ...groupDefaultState,
            isLoading: false,
            isLoaded: true,
            error: action.error
        });
        ctx.dispatch(new ChangeThemeAction('default-theme'));
    }
    /**
     * Get group request
     */
    @Action(GetGroupRequestAction)
    getGroupRequest(ctx: StateContext<GroupStateModel>, action: GetGroupRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.groupService.getGroupByUUID(action.groupUUID).pipe(
            switchMap((group: Group) => ctx.dispatch(new GetGroupSuccessAction(group))),
            catchError(err => ctx.dispatch(new GetGroupErrorAction(err)))
        );
    }
    /**
     * Get group success
     */
    @Action(GetGroupSuccessAction)
    getGroupSuccess(ctx: StateContext<GroupStateModel>, action: LoadGroupSuccessAction) {
        ctx.setState({
            isLoading: false,
            selected: action.group,
            isLoaded: true,
        });
        if (action.group.themeName) {
            ctx.dispatch(new ChangeThemeAction(action.group.themeName.toLowerCase() + '-theme'));
        } else {
            ctx.dispatch(new ChangeThemeAction('default-theme'));
        }
    }
    /**
     * Get group error
     */
    @Action(GetGroupErrorAction)
    getGroupError(ctx: StateContext<GroupStateModel>, action: LoadGroupErrorAction) {
        ctx.setState({
            ...groupDefaultState,
            isLoading: false,
            error: action.error,
            isLoaded: true
        });
    }
}
