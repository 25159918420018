import { Component, OnInit, HostBinding } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IconService } from './core/services';
import { Language } from './core/models';
import {
  ChangeLanguageAction,
  AuthenticationRequestAction,
  LogoutRequestAction
} from './core/store/auth/auth.actions';
import { LoadGroupRequestAction } from './core/store/group/group.actions';
import { AuthState, StoreSelectors } from './core/store';
import { UpdateLanguageRequestAction } from './modules/user/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class') componentCssClass;

  @Select(AuthState.theme) theme$: Observable<string>;
  @Select(AuthState.selectedLanguage) selectedLanguage$: Observable<Language>;
  @Select(StoreSelectors.appIsLoaded) appIsLoaded$: Observable<boolean>;

  constructor(private iconService: IconService, private store$: Store) {}

  ngOnInit(): void {
    this.iconService.init();
    this.store$.dispatch(new AuthenticationRequestAction());
    this.store$.dispatch(new ChangeLanguageAction(Language.FR));
    this.store$.dispatch(new LoadGroupRequestAction());
    this.theme$.subscribe(theme => (this.componentCssClass = theme));
  }

  handleLanguageChange(language: Language): void {
    this.store$
      .dispatch(new ChangeLanguageAction(language))
      .subscribe(state => {
        if (state.auth.isAuthenticated) {
          this.store$.dispatch(new UpdateLanguageRequestAction(language));
        }
      });
  }

  async handleLogout(): Promise<void> {
    this.store$.dispatch(new LogoutRequestAction());
  }
}
