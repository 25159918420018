import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHours'
})
export class SecondsToHoursPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value % 3600 / 60);
    return `${hours}h${minutes === 0 ? '' : `${minutes < 10 ? '0' + minutes : minutes}`}`;
  }

}
