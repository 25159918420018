import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthState } from '../store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

/**
 * User guard - Prevent user to access user module without authentication
 */
@Injectable()
export class UserGuard implements CanLoad {

  constructor(
    private store$: Store,
    private router: Router
  ) { }

  canLoad(): Observable<boolean> {
    return this.store$.select(AuthState.isAuthenticated).pipe(
      take(1),
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['']);
        }
        return of(isAuthenticated);
      })
    );
  }
}
