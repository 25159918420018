import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of, throwError } from 'rxjs';

import { Group } from '../models';
import { tap } from 'rxjs/operators';

/**
 * Group service - Manage group for app
 */
@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private localStorageKey = 'vls:group';
  private baseUrl = '/auth/group/';

  constructor(private apiService: ApiService) { }

  /**
   * LOAD GROUP FROM LOCALSTORAGE
   */
  public loadGroup(): Observable<Group> {
    const storedGroup: string | null = localStorage.getItem(this.localStorageKey);
    if (storedGroup) {
      const currentGroup: Group = JSON.parse(storedGroup);
      return of(currentGroup as Group);
    }
    return throwError(new Error('No group in localstorage'));
  }
  /**
   * GET /auth/group/uuid/:group_uuid
   */
  public getGroupByUUID(groupUUID: string): Observable<Group> {
    return this.apiService.get(this.baseUrl + 'uuid/' + groupUUID).pipe(
      tap((group: Group) => localStorage.setItem(this.localStorageKey, JSON.stringify(group)))
    );
  }
  /**
   * GET /auth/group/id/:id
   */
  public getGroupById(groupId: number) {
    return this.apiService.get(this.baseUrl + 'id/' + groupId);
  }

}
