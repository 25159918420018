import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

import { AuthState, GroupState, StoreSelectors } from 'src/app/core/store';
import { SidenavLink } from './models/sidenav-link.interface';
import { Group } from 'src/app/core/models';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  links: SidenavLink[] = [];
  innerWidth: number;
  mode = 'push';
  shouldOpen = false;

  @Select(StoreSelectors.isAuthenticatedAndIsInGroup) isAuthenticatedAndIsInGroup$: Observable<[boolean, boolean]>;

  @Input()
  opened: boolean;

  @Output()
  close: EventEmitter<null> = new EventEmitter();

  @Output()
  logoutClick: EventEmitter<null> = new EventEmitter();

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.updatePosition();
    this.isAuthenticatedAndIsInGroup$.subscribe(([isAuthenticated, isInGroup]: [boolean, boolean]) => {
      this.buildLink(isAuthenticated, isInGroup);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.updatePosition();
  }

  public handleMenuClose(): void {
    this.close.emit();
  }

  public handleLinkClick(): void {
    this.opened = false;
  }

  private updatePosition() {
    if (this.innerWidth > 1024) {
      this.mode = 'side';
      this.shouldOpen = true;
    } else {
      this.mode = 'push';
      this.shouldOpen = false;
    }
  }

  private buildLink(isLoggedin: boolean, isInGroup: boolean) {
    this.links = [
      {
        routerLink: '/user',
        name: 'LAYOUT.SIDENAV.LINK.PROFILE',
        themed: true,
        active: isLoggedin,
      },
      {
        routerLink: '/home',
        name: 'LAYOUT.SIDENAV.LINK.HOME',
        themed: true,
        active: !isLoggedin,
      },
      {
        routerLink: '/discover',
        name: 'LAYOUT.SIDENAV.LINK.SERVICE',
        themed: true,
        active: isInGroup,
      },
      {
        routerLink: '/offer',
        name: 'LAYOUT.SIDENAV.LINK.OFFER',
        themed: true,
        active: isInGroup,
      },
      {
        routerLink: '/contact',
        name: 'LAYOUT.SIDENAV.LINK.CONTACT',
        themed: true,
        active: true,
      },
      {
        routerLink: '/cycloan',
        name: 'LAYOUT.SIDENAV.LINK.CYCLOAN',
        themed: false,
        active: true,
      },
      {
        routerLink: '/condition',
        name: 'LAYOUT.SIDENAV.LINK.CONDITION',
        themed: false,
        active: true,

      },
      {
        routerLink: '/gpdr',
        name: 'LAYOUT.SIDENAV.LINK.GPDR',
        themed: false,
        active: true,
      },
      {
        name: 'LAYOUT.SIDENAV.LINK.SIGNOUT',
        themed: true,
        active: isLoggedin,
        click: () => this.logoutClick.emit()
      },
    ];
  }
}
