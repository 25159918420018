import { Component, Input } from '@angular/core';

import { SubscriptionPlan } from 'src/app/core/models';

@Component({
  selector: 'app-subscription-plan-card',
  templateUrl: './subscription-plan-card.component.html',
  styleUrls: ['./subscription-plan-card.component.scss']
})
export class SubscriptionPlanCardComponent {

  @Input()
  subscriptionPlan: SubscriptionPlan;

  constructor() { }

}
