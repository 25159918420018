import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor, XSRFInterceptor } from './interceptors';

import { RootTranslateModule } from './root-translate.module';

import { AuthService } from './http/auth.service';

import { StoreModule } from './store/store.module';

@NgModule({
  imports: [
    HttpClientModule,
    HttpClientXsrfModule,
    RootTranslateModule,
    StoreModule
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XSRFInterceptor, multi: true }
  ]
})
export class CoreModule { }
