import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, finalize } from 'rxjs/operators';

import { AuthService } from '../http';
// import { Store } from '@ngxs/store';
// import { LogoutRequestAction } from '../store';

/**
 * Token Interceptor
 * Pass credentials to request,
 * Intercept 401 error and try a refresh token
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshInProgress = false;
    private httpTokenResponse$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private authService: AuthService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ withCredentials: true }); // pass credentials
        return next.handle(request)
            .pipe(
                catchError((error: any) => { // catch error
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) { // if 401 not from refresh-token or login endpoint
                            if (request.url.includes('refresh-token') || request.url.includes('login')) {
                                // do nothing for login error
                                if (request.url.includes('refresh-token')) {
                                    this.authService.logout();
                                }
                            } else {
                                return this.handle401Error(request, next); // try a refresh
                            }
                        }
                        return this.handleError(error);
                    }
                    return this.handleError(error);
                })
            );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshInProgress) {
            this.isRefreshInProgress = true;
            return this.authService.refreshToken().pipe(
                switchMap(() => {
                    this.httpTokenResponse$.next(true);
                    return next.handle(request);
                }),
                catchError(err => {
                    this.authService.logout();
                    return this.handleError(err);
                }),
                finalize(() => this.isRefreshInProgress = false)
            );
        } else {
            return this.httpTokenResponse$.pipe(switchMap(() => next.handle(request)));
        }
    }

    private handleError(error) {
        return throwError(error);
    }
}
