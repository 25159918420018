import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule  } from '@ngxs/logger-plugin';

import { environment } from 'src/environments/environment';

import { AuthState } from './auth/auth.state';
import { GroupState } from './group/group.state';
import { SubscriptionPlanState } from './subscription-plan/subscription-plan.state';

@NgModule({
    imports: [
        NgxsModule.forRoot(
            [
                AuthState,
                GroupState,
                SubscriptionPlanState,
            ],
            { developmentMode: !environment.production }
        ),
        environment.production
            ? []
            : [
                NgxsReduxDevtoolsPluginModule.forRoot(),
                NgxsLoggerPluginModule.forRoot()
            ]
    ]
})
export class StoreModule { }
