import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from './material/material.module';

import {
  ButtonComponent,
  PageTitleComponent,
  PageComponent,
  StepperComponent,
  CardComponent,
  BankCardFormComponent,
  SubscriptionPlanCardComponent,
  BackButtonComponent,
} from './components';

import {
  MinutesToHoursPipe,
  SecondsToHoursPipe,
  CapitalizePipe,
  ToHourPipe,
  NumberToStringPipe,
  SecondsToAppropriateUnitPipe,
  CentsToEurosPipe
} from './pipes';
import { SubscriptionPlanGridComponent } from './components/subscription-plan-grid/subscription-plan-grid.component';

const COMPONENTS = [
  ButtonComponent,
  PageTitleComponent,
  PageComponent,
  StepperComponent,
  CardComponent,
  BankCardFormComponent,
  SubscriptionPlanCardComponent,
  SubscriptionPlanGridComponent,
  BackButtonComponent,
];

const PIPES = [
  MinutesToHoursPipe,
  SecondsToHoursPipe,
  CapitalizePipe,
  ToHourPipe,
  NumberToStringPipe,
  SecondsToAppropriateUnitPipe,
  CentsToEurosPipe,
  SecondsToAppropriateUnitPipe
];

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  TranslateModule
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
  ],
  imports: [...MODULES],
  exports: [
    ...COMPONENTS,
    ...PIPES,
    ...MODULES
  ]
})
export class SharedModule { }
