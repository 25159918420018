import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { User, Contract, Credentials } from 'src/app/core/models';
import { ApiService, AuthService } from 'src/app/core/http';


@Injectable()
export class UserService {

  private baseUrl = '/user';

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }
  /**
   * PATCH /user
   */
  public updateProfile(user: Partial<User>): Observable<User> {
    return this.apiService.patch(this.baseUrl, user).pipe(
      switchMap(() => this.authService.me())
    );
  }
  /**
   * GET /contract/:groupId
   */
  public getContracts(groupId: number): Observable<Contract[]> {
      return this.apiService.get(this.baseUrl + '/contract/' + groupId);
  }
  /**
   * POST /update-password
   */
  public updatePassword(credentials: Credentials): Observable<void> {
    return this.apiService.patch(this.baseUrl + '/update-password', credentials);
  }
  /**
   * GET /bank-card
   */
  public getBankCard(): Observable<any> {
    return this.apiService.get(this.baseUrl + '/bank-card');
  }
  /**
   * PATCH /bank-card
   */
  public updateBankCard(intent: SetupIntent): Observable<any> {
    return this.apiService.patch(this.baseUrl + '/bank-card', {
      paymentMethod: intent.payment_method
    });
  }
  /**
   * POST /user/contract
   */
  createContract({ group, subscriptionPlan }): Observable<Contract> {
    const body = {
      groupId: group.id,
      subscriptionPlanId: subscriptionPlan.id
    };
    return this.apiService.post(this.baseUrl + '/contract', body);
  }
}
