import { Injectable } from '@angular/core';
import { stripe } from 'src/libs';

/**
 * Stripe service - Service for stripe js
 */
@Injectable({
  providedIn: 'root'
})
export class StripeService {

  /**
   * Confirm setup intent
   * @param intent the setup intent from back end
   * @param card the bank card element
   * @param email customer email
   */
  async cardSetup(intent: SetupIntent, card: StripeElementsCard, email: string): Promise<SetupIntent> {
    const { token } = await stripe.createToken(card);
    if (!token) {
      throw new Error('Card setup intent failed');
    }
    if (token.card.funding !== 'credit' && token.card.funding !== 'debit') {
      throw new Error('Card must be credit or debit');
    }
    const { error, setupIntent } = await stripe.handleCardSetup(intent.client_secret, card, {
      payment_method_data: {
        billing_details: { email }
      }
    });
    if (error) {
      throw new Error('Card setup intent failed');
    }
    return {
      ...setupIntent,
      card: {
        id: token.card.id,
        funding: token.card.funding
      }
    };
  }
}
