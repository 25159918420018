import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * XSR interceptor
 * Inject xsrf token in request header
 */
@Injectable()
export class XSRFInterceptor implements HttpInterceptor {

    constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenExtractor.getToken() as string;
        if (token !== null) {
          request = request.clone({ setHeaders: { 'X-XSRF-TOKEN': token } });
        }
        return next.handle(request);
    }
}
