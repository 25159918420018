import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material';



@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private config: MatSnackBarConfig = {
    duration: 3000
  };

  constructor(private snackBar: MatSnackBar) { }

  public info(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, null, {
      data: message,
      panelClass: ['app-info-snackbar'],
      ...this.config
    });
  }

  public danger(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, null, {
      data: message,
      panelClass: ['app-danger-snackbar'],
      ...this.config
    });
  }
}
