import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidenav-link',
  templateUrl: './sidenav-link.component.html',
  styleUrls: ['./sidenav-link.component.scss']
})
export class SidenavLinkComponent {

  @Input()
  routerLink: string;

  @Input()
  themed: boolean;

  @Output()
  linkClick: EventEmitter<null> = new EventEmitter();


  handleClick() {
    this.linkClick.emit();
  }
}
