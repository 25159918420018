import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Credentials } from 'src/app/core/models';

import { ApiService } from './api.service';

/**
 * Auth service - Service to request to manage authentication
 */
@Injectable()
export class AuthService {

  constructor(private apiService: ApiService) { }

  /**
   * POST /auth/login
   */
  login(credentials: Credentials): Observable<any> {
    return this.apiService.post('/auth/login', credentials);
  }
  /**
   * GET /auth/refresh-token
   */
  refreshToken(): Observable<any> {
    return this.apiService.get('/auth/refresh-token');
  }
  /**
   * GET /auth/logout
   */
  logout(): Observable<void> {
    return this.apiService.get('/auth/logout');
  }
  /**
   * GET /auth/reset-password/:email
   */
  resetPassword(email: string): Observable<any> {
    return this.apiService.get('/auth/reset-password/' + email);
  }
  /**
   * GET /auth/payment/card-wallet
   */
  getCardWallet(): Observable<SetupIntent> {
    return this.apiService.get('/auth/payment/card-wallet');
  }
  /**
   * POST /auth/contact
   */
  createContact(message: string): Observable<void> {
    return this.apiService.post('/auth/contact', message);
  }
  /**
   * GET /user/me
   */
  me() {
    return this.apiService.get('/user/me');
  }
}
