import { Group, SubscriptionPlan } from '../../models';

export enum SubscriptionPlanActionTypes {
    GET_SUBSCRIPTION_PLAN_REQUEST = '[Subscription plan] Get request',
    GET_SUBSCRIPTION_PLAN_SUCCESS = '[Subscription plan] Get success',
    GET_SUBSCRIPTION_PLAN_ERROR = '[Subscription plan] Get error',

    SET_SELECTED_SUBSCRIPTION_PLAN = '[Subscription plan] Set selected'
}

export class GetSubscriptionPlanRequestAction {
    static readonly type = SubscriptionPlanActionTypes.GET_SUBSCRIPTION_PLAN_REQUEST;
    constructor(public group: Group) { }
}

export class GetSubscriptionPlanSuccessAction {
    static readonly type = SubscriptionPlanActionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS;
    constructor(public subscriptionPlans: SubscriptionPlan[]) { }
}

export class GetSubscriptionPlanErrorAction {
    static readonly type = SubscriptionPlanActionTypes.GET_SUBSCRIPTION_PLAN_ERROR;
    constructor(public error: string) { }
}

export class SetSelectedSubscriptionPlanAction {
    static readonly type = SubscriptionPlanActionTypes.SET_SELECTED_SUBSCRIPTION_PLAN;
    constructor(public subscriptionPlan: SubscriptionPlan) { }
}
