import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GroupResolver } from './core/resolvers/group.resolver';

import { RegisterGuard, UserGuard } from './core/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: 'group/:groupUUID',
    loadChildren: './modules/auth/auth.module#AuthModule',
    resolve: { groupUUID: GroupResolver }
  },
  {
    path: 'register',
    loadChildren: './modules/register/register.module#RegisterModule',
    canLoad: [RegisterGuard]
  },
  {
    path: 'user',
    loadChildren: './modules/user/user.module#UserModule',
    canLoad: [UserGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    RegisterGuard,
    UserGuard,
    GroupResolver,
  ]
})
export class AppRoutingModule { }
