import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToAppropriateUnit'
})
export class SecondsToAppropriateUnitPipe implements PipeTransform {

  transform(seconds: number): string {

    let intValue: number;
    let unit: string;

    if (seconds % 3600 === 0) {
      intValue = seconds / 3600;
      unit = 'h.';
    } else if (seconds % 60 === 0) {
      intValue = seconds / 60;
      unit = 'min.';
    } else {
      intValue = seconds;
      unit = 's.';
    }

    return `${intValue === 1 ? '' : intValue.toString()}${unit}`;
  }
}
