import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input()
  outlined: boolean;

  @Input()
  disabled: boolean;

  @Input()
  type: string;

  @Input()
  size: string;

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.size = this.size ? this.size : '';
  }

  handleClick(event: any) {
    this.buttonClick.emit(event);
  }
}
