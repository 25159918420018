import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { SidenavLinkComponent } from './sidenav-link/sidenav-link.component';
import { SidenavComponent } from './sidenav.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    SidenavComponent,
    SidenavLinkComponent
  ],
  exports: [SidenavComponent]
})
export class SidenavModule { }
