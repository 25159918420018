import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { DefaultLayoutComponent } from './default-layout.component';
import { HeaderModule } from './header/header.module';
import { FooterComponent } from './footer/footer.component';
import { SidenavModule } from './sidenav/sidenav.module';

@NgModule({
  declarations: [
    DefaultLayoutComponent,
    FooterComponent,
  ],
  imports: [
    SharedModule,
    HeaderModule,
    SidenavModule,
  ],
  exports: [
    DefaultLayoutComponent,
  ]
})
export class DefaultLayoutModule { }
