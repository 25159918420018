import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, switchMap } from 'rxjs/operators';

import {
    LoginRequestAction,
    AuthenticatedAction,
    LoginSuccessAction,
    LoginErrorAction,
    NotAuthenticatedAction,
    LogoutRequestAction,
    LogoutErrorAction,
    LogoutSuccessAction,
    ChangeLanguageAction,
    AuthenticationRequestAction,
    SendValidationEmailRequestAction,
    SendValidationEmailSuccessAction,
    SendValidationEmailErrorAction,
    UpdateUserAction,
    GetCardWalletErrorAction,
    GetCardWalletRequestAction,
    GetCardWalletSuccessAction,
    ChangeThemeAction,
    ResetPasswordRequestAction,
    ResetPasswordErrorAction,
    ResetPasswordSuccessAction,
    CreateContactRequestAction,
    CreateContactSuccessAction,
    CreateContactErrorAction
} from './auth.actions';

import { AuthService, EmailService } from '../../http';

import { User, Language } from '../../models';
import { LanguageService } from '../../services/language.service';
import { SnackbarService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../services';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';

export interface AuthStateModel {
    user: User | null;
    isAuthenticated: boolean;
    isActive: boolean;
    isLoading: boolean;
    selectedLanguage: Language;
    theme: string;
    intent?: SetupIntent;
    isLoaded?: boolean;
    error?: string;
}


export const authDefaultState: AuthStateModel = {
    user: null,
    selectedLanguage: Language.FR,
    isAuthenticated: false,
    isActive: false,
    isLoading: false,
    theme: 'default-theme'
};

/**
 * Auth state - Current stored state of auth
 */
@State<AuthStateModel>({
    name: 'auth',
    defaults: authDefaultState
})
export class AuthState {

    constructor(
        private authService: AuthService,
        private languageService: LanguageService,
        private emailService: EmailService,
        private snackbarService: SnackbarService,
        private translateService: TranslateService,
        private themeService: ThemeService,
        private router: Router,
        private ngZone: NgZone
    ) { }
    /**
     * Select current user
     */
    @Selector()
    static user(state: AuthStateModel) {
        return state.user;
    }
    /**
     * Select selected language
     */
    @Selector()
    static selectedLanguage(state: AuthStateModel) {
        return state.selectedLanguage;
    }
    /**
     * Select user is authenticated
     */
    @Selector()
    static isAuthenticated(state: AuthStateModel) {
        return state.isAuthenticated;
    }
    /**
     * Select if user have an active email
     */
    @Selector()
    static isActive(state: AuthStateModel) {
        return state.isActive;
    }
    /**
     * Select auth is loading
     */
    @Selector()
    static isLoading(state: AuthStateModel) {
        return state.isLoading;
    }
    /**
     * Select auth is loaded
     */
    @Selector()
    static isLoaded(state: AuthStateModel) {
        return state.isLoaded;
    }
    /**
     * Select error
     */
    @Selector()
    static error(state: AuthStateModel) {
        return state.error;
    }
    /**
     * Select stripe setup intent
     */
    @Selector()
    static intent(state: AuthStateModel) {
        return state.intent;
    }
    /**
     * Select current theme
     */
    @Selector()
    static theme(state: AuthStateModel) {
        return state.theme;
    }
    /**
     * Autentication request
     */
    @Action(AuthenticationRequestAction)
    authenticationRequest(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true,
            isLoaded: false
        });
        return this.authService.me().pipe(
            switchMap((user: User) => ctx.dispatch(new AuthenticatedAction(user))),
            catchError(() => ctx.dispatch(new NotAuthenticatedAction()))
        );
    }
    /**
     * Authenticated
     */
    @Action(AuthenticatedAction)
    authenticated(ctx: StateContext<AuthStateModel>, action: AuthenticatedAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            user: action.user,
            isAuthenticated: true,
            isLoading: false,
            isLoaded: true,
            theme: state.theme,
            isActive: action.user.isEmailActivate,
        });
        this.ngZone.run(() => this.router.navigate(['user']));
        if (action.user.language) {
            ctx.dispatch(new ChangeLanguageAction(action.user.language));
        }
    }
    /**
     * Not authenticated
     */
    @Action(NotAuthenticatedAction)
    NotAuthenticatedAction(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            isLoaded: true
        });
    }
    /**
     * Login request
     */
    @Action(LoginRequestAction)
    loginRequest(ctx: StateContext<AuthStateModel>, action: LoginRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.authService.login(action.credentials).pipe(
            switchMap((user: User) => ctx.dispatch(new LoginSuccessAction(user))),
            catchError(err => ctx.dispatch(new LoginErrorAction(err)))
        );
    }
    /**
     * Login success
     */
    @Action(LoginSuccessAction)
    loginSuccess(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            isLoaded: false,
            error: null
        });
        this.translateService.get('MESSAGES.LOGIN.SUCCESS').subscribe(msg => this.snackbarService.info(msg));
        return ctx.dispatch(new AuthenticationRequestAction());
    }
    /**
     * Login error
     */
    @Action(LoginErrorAction)
    loginError(ctx: StateContext<AuthStateModel>, action: LoginErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
        if (action.error.status === 403) {
            this.translateService.get('MESSAGES.ACCOUNT_INVALID').subscribe(msg => this.snackbarService.danger(msg));
        } else {
            this.translateService.get('MESSAGES.LOGIN.ERROR').subscribe(msg => this.snackbarService.danger(msg));
        }
    }
    /**
     * Logout request
     */
    @Action(LogoutRequestAction)
    logoutRequest(ctx: StateContext<AuthStateModel>) {
        return this.authService.logout().pipe(
            switchMap(() => ctx.dispatch(new LogoutSuccessAction())),
            catchError(err => ctx.dispatch(new LogoutErrorAction(err)))
        );
    }
    /**
     * Logout success
     */
    @Action(LogoutSuccessAction)
    logoutSuccess(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            isLoaded: true,
            user: null,
            isAuthenticated: false,
            isLoading: false,
            theme: state.theme,
            isActive: false,
            selectedLanguage: state.selectedLanguage
        });
        this.ngZone.run(() => this.router.navigate(['']));
    }
    /**
     * Reset password request
     */
    @Action(ResetPasswordRequestAction)
    ResetPasswordRequest(ctx: StateContext<AuthStateModel>, action: ResetPasswordRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.authService.resetPassword(action.email).pipe(
            switchMap(() => ctx.dispatch(new ResetPasswordSuccessAction())),
            catchError(err => ctx.dispatch(new ResetPasswordErrorAction(err.message)))
        );
    }
    /**
     * Reset password success
     */
    @Action(ResetPasswordSuccessAction)
    resetPasswordSuccess(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false
        });
    }
    /**
     * Reset password error
     */
    @Action(ResetPasswordErrorAction)
    resetPasswordError(ctx: StateContext<AuthStateModel>, action: ResetPasswordErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
    /**
     * Send validation email request
     */
    @Action(SendValidationEmailRequestAction)
    sendValidationEmailRequest(ctx: StateContext<AuthStateModel>, action: SendValidationEmailRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true,
        });
        return this.emailService.sendEmailValidation(action.email).pipe(
            switchMap(() => ctx.dispatch(new SendValidationEmailSuccessAction())),
            catchError(err => ctx.dispatch(new SendValidationEmailErrorAction(err.message)))
        );
    }
    /**
     * Send validaation email success
     */
    @Action(SendValidationEmailSuccessAction)
    sendValidationEmailSuccess(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
        });
    }
    /**
     * Send validation email error
     */
    @Action(SendValidationEmailErrorAction)
    sendValidationEmailError(ctx: StateContext<AuthStateModel>, action: SendValidationEmailErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
    /**
     * Get card wallet request
     */
    @Action(GetCardWalletRequestAction)
    getCardWalletRequest(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.authService.getCardWallet().pipe(
            switchMap((intent: SetupIntent) => ctx.dispatch(new GetCardWalletSuccessAction(intent))),
            catchError(err => ctx.dispatch(new GetCardWalletErrorAction(err.message)))
        );
    }
    /**
     * Get card wallet success
     */
    @Action(GetCardWalletSuccessAction)
    getCardWalletSuccess(ctx: StateContext<AuthStateModel>, action: GetCardWalletSuccessAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            intent: action.intent
        });
    }
    /**
     * Get card wallet error
     */
    @Action(GetCardWalletErrorAction)
    getCardWalletError(ctx: StateContext<AuthStateModel>, action: GetCardWalletErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
    /**
     * Create contact request
     */
    @Action(CreateContactRequestAction)
    createContactRequest(ctx: StateContext<AuthStateModel>, action: CreateContactRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true,
        });
        return this.authService.createContact(action.message).pipe(
            switchMap(() => ctx.dispatch(new CreateContactSuccessAction())),
            catchError(err => ctx.dispatch(new CreateContactErrorAction(err.message)))
        );
    }
    /**
     * Create contact success
     */
    @Action(CreateContactSuccessAction)
    createContactSuccess(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
        });
        this.translateService.get('MESSAGES.CONTACT.SUCCESS').subscribe(msg => this.snackbarService.info(msg));
    }
    /**
     * Create contact error
     */
    @Action(CreateContactErrorAction)
    createContactError(ctx: StateContext<AuthStateModel>, action: CreateContactErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
        this.translateService.get('MESSAGES.CONTACT.ERROR').subscribe(msg => this.snackbarService.info(msg));
    }
    /**
     * Change language
     */
    @Action(ChangeLanguageAction)
    changeLanguage(ctx: StateContext<AuthStateModel>, action: ChangeLanguageAction) {
        this.languageService.setLanguage(action.language);
        const state = ctx.getState();
        ctx.setState({
            ...state,
            user: {
                ...state.user,
                language: action.language
            },
            selectedLanguage: action.language
        });
    }
    /**
     * Update current user
     */
    @Action(UpdateUserAction)
    updateUser(ctx: StateContext<AuthStateModel>, action: UpdateUserAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            user: {
                ...state.user,
                ...action.user
            }
        });
    }
    /**
     * Change current theme
     */
    @Action(ChangeThemeAction)
    changeTheme(ctx: StateContext<AuthStateModel>, action: ChangeThemeAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            theme: action.themeName
        });
        this.themeService.setTheme(action.themeName);
    }
}
