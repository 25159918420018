import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: any): any {
    const formatedHours = Math.floor(value / 60);
    const formatedMinutes = value % 60;
    return `${formatedHours < 10
      ? '0' + formatedHours
      : formatedHours}h${formatedMinutes < 10
        ? '0' + formatedMinutes
        : formatedMinutes}`;
  }
}
