import { Group } from '../../models';

export enum GroupActionTypes {
    LOAD_GROUP_REQUEST = '[Group] Load request',
    LOAD_GROUP_SUCCESS = '[Group] Load success',
    LOAD_GROUP_ERROR = '[Group] Load error',

    GET_GROUP_REQUEST = '[Group] Get group request',
    GET_GROUP_SUCCESS = '[Group] Get group success',
    GET_GROUP_ERROR = '[Group] Get group error',

}

export class LoadGroupRequestAction {
    static readonly type = GroupActionTypes.LOAD_GROUP_REQUEST;
}

export class LoadGroupSuccessAction {
    static readonly type = GroupActionTypes.LOAD_GROUP_SUCCESS;
    constructor(public group: Group) { }
}

export class LoadGroupErrorAction {
    static readonly type = GroupActionTypes.LOAD_GROUP_ERROR;
    constructor(public error: string) { }
}

export class GetGroupRequestAction {
    static readonly type = GroupActionTypes.GET_GROUP_REQUEST;
    constructor(public groupUUID: string) { }
}

export class GetGroupSuccessAction {
    static readonly type = GroupActionTypes.GET_GROUP_SUCCESS;
    constructor(public group: Group) { }
}

export class GetGroupErrorAction {
    static readonly type = GroupActionTypes.GET_GROUP_ERROR;
    constructor(public error: string) { }
}
