import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

/**
 * Theme service - Allow to change the apptheme
 */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private overlayContainer: OverlayContainer) { }
  /**
   * Set theme by css class name @see '~src/sass/themes/main' for theme classes
   */
  setTheme(name: string): void {
    const classList = this.overlayContainer.getContainerElement().classList;
    const classesToRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
    if (classesToRemove.length) {
      classList.remove(...classesToRemove);
    }
    classList.add(name);
  }
}
