import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-burger-button',
  templateUrl: './burger-button.component.html',
  styleUrls: ['./burger-button.component.scss']
})
export class BurgerButtonComponent {

  @ViewChild('burgerButton', { static: false })
  buttonRef: any;

  @Output()
  burgerClick: EventEmitter<void> = new EventEmitter();

  handleClick() {
    // little hack to disabled focus when page change
    this.buttonRef._elementRef.nativeElement.blur();
    this.burgerClick.emit();
  }

}
