import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Store } from '@ngxs/store';
import { SubscriptionPlanState } from '../store';
import { SubscriptionPlan } from '../models';

/**
 * Register guard, prevent user to access register module without selected subscription plan
 */
@Injectable()
export class RegisterGuard implements CanLoad {

  constructor(
    private router: Router,
    private store$: Store
  ) { }

  canLoad(): boolean {
    const plan: SubscriptionPlan = this.store$.selectSnapshot<SubscriptionPlan>(SubscriptionPlanState.selected);
    if (plan) {
      return true;
    }
    this.router.navigate(['customer']);
    return false;
  }

}
