import { Component, Output, EventEmitter } from '@angular/core';

import { Language } from 'src/app/core/models/user/language.enum';
import { Select } from '@ngxs/store';
import { AuthState } from 'src/app/core/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Select(AuthState.selectedLanguage) selectedLanguage$: Observable<Language>;
  @Select(AuthState.theme) theme$: Observable<string>;

  @Output()
  burgerClick: EventEmitter<null> = new EventEmitter();

  @Output()
  languageChange: EventEmitter<Language> = new EventEmitter();

  public handleBurgerClick(): void {
    this.burgerClick.emit();
  }

  public handleLanguageChange(selectedLanguage: Language): void {
    this.languageChange.emit(selectedLanguage);
  }

}
