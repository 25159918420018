import { Component, Output, EventEmitter, Input } from '@angular/core';

import { Language } from 'src/app/core/models/user/language.enum';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss']
})
export class LanguageButtonComponent {

  @Input()
  public selectedLanguage: Language = Language.FR;

  public language = Language;

  @Output()
  public lauguageChange: EventEmitter<Language> = new EventEmitter();

  public handleLanguageChange(selectedLanguage: Language): void {
    this.selectedLanguage = selectedLanguage;
    this.lauguageChange.emit(selectedLanguage);
  }

}
