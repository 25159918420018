import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { LanguageButtonComponent } from './language-button/language-button.component';
import { BurgerButtonComponent } from './burger-button/burger-button.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderLogoComponent } from './header-logo/header-logo.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    HeaderComponent,
    LanguageButtonComponent,
    BurgerButtonComponent,
    HeaderLogoComponent
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
