import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { catchError, switchMap } from 'rxjs/operators';

import { Contract, Group, User } from 'src/app/core/models';
import {
    GetContractsRequestAction,
    GetContractsErrorAction,
    GetContractsSuccessAction,
    UpdateProfileRequestAction,
    UpdateProfileErrorAction,
    UpdateProfileSuccessAction,
    UpdatePasswordRequestAction,
    UpdatePasswordErrorAction,
    UpdatePasswordSuccessAction,
    GetBankCardRequestAction,
    GetBankCardSuccessAction,
    GetBankCardErrorAction,
    UpdateBankCardRequestAction,
    UpdateBankCardErrorAction,
    UpdateBankCardSuccessAction,
    UpdateLanguageRequestAction,
    UpdateLanguageSuccessAction,
    UpdateLanguageErrorAction,
    CreateContractRequestAction,
    CreateContractSuccessAction,
    CreateContractErrorAction
} from './user.actions';
import { UserService } from '../services/user.service';
import { UpdateUserAction, AuthState } from 'src/app/core/store';
import { StripeService } from 'src/app/core/services/stripe.service';
import { from } from 'rxjs';
import { AuthService } from 'src/app/core/http';
import { SnackbarService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';

export interface UserStateModel {
    isLoading: boolean;
    contracts: Contract[];
    card?: any;
    error?: string;
}

export const userDefaultState: UserStateModel = {
    isLoading: false,
    contracts: []
};

@State<UserStateModel>({
    name: 'user',
    defaults: userDefaultState
})
export class UserState {

    constructor(
        private userService: UserService,
        private stripeService: StripeService,
        private store$: Store,
        private snackbarService: SnackbarService,
        private translateService: TranslateService,
        private zone: NgZone,
        private router: Router
    ) { }
    /**
     * Select if user is loading
     */
    @Selector()
    static isLoading(state: UserStateModel): boolean {
        return state.isLoading;
    }
    /**
     * Select user contracts
     */
    @Selector()
    static contracts(state: UserStateModel): Contract[] {
        return state.contracts;
    }
    /**
     * Select user card
     */
    @Selector()
    static card(state: UserStateModel): any {
        return state.card;
    }
    /**
     * Get contracts request
     */
    @Action(GetContractsRequestAction)
    getContractRequest(ctx: StateContext<UserStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        const group: Group = this.store$.selectSnapshot<Group>(appState => appState.group.selected);
        if (group) {
            return this.userService.getContracts(group.id).pipe(
                switchMap((contracts: Contract[]) => ctx.dispatch(new GetContractsSuccessAction(contracts))),
                catchError(err => ctx.dispatch(new GetContractsErrorAction(err.message)))
            );
        }
    }
    /**
     * Get contracts success
     */
    @Action(GetContractsSuccessAction)
    getContractsSuccess(ctx: StateContext<UserStateModel>, action: GetContractsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            contracts: action.contracts
        });
    }
    /**
     * Get contracts error
     */
    @Action(GetContractsErrorAction)
    getContractsError(ctx: StateContext<UserStateModel>, action: GetContractsErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
    /**
     * Update profile request
     */
    @Action(UpdateProfileRequestAction)
    updateProfileRequest(ctx: StateContext<UserStateModel>, action: UpdateProfileRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading:  true
        });
        return this.userService.updateProfile(action.user).pipe(
            switchMap((user: User) => ctx.dispatch(new UpdateProfileSuccessAction(user))),
            catchError(err => ctx.dispatch(new UpdateProfileErrorAction(err.message)))
        );
    }
    /**
     * Update profile success
     */
    @Action(UpdateProfileSuccessAction)
    updateProfileSuccess(ctx: StateContext<UserStateModel>, action: UpdateProfileSuccessAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading:  false
        });
        ctx.dispatch(new UpdateUserAction(action.user));
        this.translateService.get('MESSAGES.UPDATE_PROFILE.SUCCESS').subscribe(msg => this.snackbarService.info(msg));
    }
    /**
     * Update profile error
     */
    @Action(UpdateProfileErrorAction)
    updateProfileError(ctx: StateContext<UserStateModel>, action: UpdateProfileErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading:  false,
            error: action.error
        });
        this.translateService.get('MESSAGES.UPDATE_PROFILE.ERROR').subscribe(msg => this.snackbarService.danger(msg));
    }
    /**
     * Update password request
     */
    @Action(UpdatePasswordRequestAction)
    updatePasswordRequest(ctx: StateContext<UserStateModel>, action: UpdatePasswordRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        const user: User = this.store$.selectSnapshot<User>(AuthState.user);
        return this.userService.updatePassword({
            email: user.email,
            password: action.payload.password,
            newPassword: action.payload.newPassword
        }).pipe(
            switchMap(() => ctx.dispatch(new UpdatePasswordSuccessAction())),
            catchError(err => ctx.dispatch(new UpdatePasswordErrorAction(err.message)))
        );
    }
    /**
     * Update password success
     */
    @Action(UpdatePasswordSuccessAction)
    updatePasswordSuccess(ctx: StateContext<UserStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false
        });
        this.translateService.get('MESSAGES.UPDATE_PASSWORD.SUCCESS').subscribe((msg: string) => {
            this.snackbarService.info(msg);
            this.zone.run(() => this.router.navigate(['user']));
        });
    }
    /**
     * Update password error
     */
    @Action(UpdatePasswordErrorAction)
    updatePasswordError(ctx: StateContext<UserStateModel>, action: UpdatePasswordErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
        this.translateService.get('MESSAGES.UPDATE_PASSWORD.ERROR').subscribe(msg => this.snackbarService.danger(msg));
    }
    /**
     * Get bank card request
     */
    @Action(GetBankCardRequestAction)
    getBankCardRequest(ctx: StateContext<UserStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.userService.getBankCard().pipe(
            switchMap(card => ctx.dispatch(new GetBankCardSuccessAction(card))),
            catchError(err => ctx.dispatch(new GetBankCardErrorAction(err.message)))
        );
    }
    /**
     * Get bank card success
     */
    @Action(GetBankCardSuccessAction)
    getBankCardSuccess(ctx: StateContext<UserStateModel>, action: GetBankCardSuccessAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            card: action.card,
            isLoading: false
        });
    }
    /**
     * Get bank card error
     */
    @Action(GetBankCardErrorAction)
    getBankCardError(ctx: StateContext<UserStateModel>, action: GetBankCardErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            error: action.error
        });
    }
    /**
     * Update bank card request
     */
    @Action(UpdateBankCardRequestAction)
    updateBankCardRequest(ctx: StateContext<UserStateModel>, action: UpdateBankCardRequestAction) {
        const intent = this.store$.selectSnapshot(AuthState.intent);
        const user: User = this.store$.selectSnapshot<User>(AuthState.user);
        return from(this.stripeService.cardSetup(intent, action.card, user.email)).pipe(
            switchMap((setupIntent: SetupIntent) => {
                return this.userService.updateBankCard(setupIntent).pipe(
                    switchMap((card) => ctx.dispatch(new UpdateBankCardSuccessAction(card))),
                    catchError(err => ctx.dispatch(new UpdateBankCardErrorAction(err.message))),
                );
            }),
            catchError(err => ctx.dispatch(new UpdateBankCardErrorAction(err.message)))
        );
    }
    /**
     * Update bank card success
     */
    @Action(UpdateBankCardSuccessAction)
    updateBankCardSuccess(ctx: StateContext<UserStateModel>, action: UpdateBankCardSuccessAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            card: action.card
        });
        this.translateService.get('MESSAGES.UPDATE_BANK_CARD.SUCCESS').subscribe(msg => this.snackbarService.info(msg));
    }
    /**
     * Update bank card error
     */
    @Action(UpdateBankCardErrorAction)
    updateBankCardError(ctx: StateContext<UserStateModel>, action: UpdateBankCardErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
        this.translateService.get('MESSAGES.UPDATE_BANK_CARD.ERROR').subscribe(msg => this.snackbarService.danger(msg));
    }
    /**
     * Update user language request
     */
    @Action(UpdateLanguageRequestAction)
    updateLanguageRequest(ctx: StateContext<UserStateModel>, action: UpdateLanguageRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.userService.updateProfile({ language: action.language }).pipe(
            switchMap(() => ctx.dispatch(new UpdateLanguageSuccessAction())),
            catchError(err => ctx.dispatch(new UpdateLanguageErrorAction(err.message)))
        );
    }
    /**
     * Update user language success
     */
    @Action(UpdateLanguageSuccessAction)
    updateLanguageSuccess(ctx: StateContext<UserStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false
        });
    }
    /**
     * Update user language error
     */
    @Action(UpdateLanguageSuccessAction)
    updateLanguageError(ctx: StateContext<UserStateModel>, action: UpdateLanguageErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
    /**
     * Create contract request
     */
    @Action(CreateContractRequestAction)
    createContractRequest(ctx: StateContext<UserStateModel>, action: CreateContractRequestAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.userService.createContract({
            group: action.payload.group,
            subscriptionPlan: action.payload.subscriptionPlan
        }).pipe(
            switchMap((contract: Contract) => ctx.dispatch(new CreateContractSuccessAction(contract))),
            catchError(err => ctx.dispatch(new CreateContractErrorAction(err.message)))
        );
    }
    /**
     * Create contract request
     */
    @Action(CreateContractSuccessAction)
    createContractSuccess(ctx: StateContext<UserStateModel>, action: CreateContractSuccessAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            contracts: [...state.contracts, action.contract]
        });
    }
    /**
     * Create contract request
     */
    @Action(CreateContractErrorAction)
    createContractError(ctx: StateContext<UserStateModel>, action: CreateContractErrorAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
}
