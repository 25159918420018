export const environment = {
  production: true,
  localStoragePrefix: 'vls',
  api: {
    serverUrl: 'https://user.inte.cycloan.fr/api',
    version: 1
  },
  keycloak: {
    serverUrl: 'https://user.inte.cycloan.fr/auth',
    clientId: 'vls',
    realm: 'noval'
  },
  stripe: {
    key: 'pk_test_83SKm9F1mppgD8K9KkDXIo1h00nGOSwh5S',
  },
};
