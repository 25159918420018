import { Contract, User, Language, Group, SubscriptionPlan } from 'src/app/core/models';

export enum UserActionTypes {
    GET_CONTRACTS_REQUEST = '[User] Get contracts request',
    GET_CONTRACTS_SUCCESS = '[User] Get contracts success',
    GET_CONTRACT_ERROR = '[User] Get contracts error',

    UPDATE_PROFILE_REQUEST = '[User] Update profile request',
    UPDATE_PROFILE_SUCCESS = '[User] Update profile success',
    UPDATE_PROFILE_ERROR = '[User] Update profile error',

    UPDATE_PASSWORD_REQUEST = '[User] Update password request',
    UPDATE_PASSWORD_SUCCESS = '[User] Update password success',
    UPDATE_PASSWORD_ERROR = '[User] Update password error',

    GET_BANK_CARD_REQUEST = '[User] Get bank card request',
    GET_BANK_CARD_SUCCESS = '[User] Get bank card success',
    GET_BANK_CARD_ERROR = '[User] Get bank card error',

    UPDATE_BANK_CARD_REQUEST = '[User] Update bank card request',
    UPDATE_BANK_CARD_SUCCESS = '[User] Update bank card success',
    UPDATE_BANK_CARD_ERROR = '[User] Update bank card error',

    CREATE_CONTRACT_REQUEST = '[User] Create contract request',
    CREATE_CONTRACT_SUCCESS = '[User] Create contract success',
    CREATE_CONTRACT_ERROR = '[User] Create contract error',

    UPDATE_LANGUAGE_REQUEST = '[User] Update language request',
    UPDATE_LANGUAGE_SUCCESS = '[User] Update language success',
    UPDATE_LANGUAGE_ERROR = '[User] Update language error',
}


export class GetContractsRequestAction {
    static readonly type = UserActionTypes.GET_CONTRACTS_REQUEST;
}

export class GetContractsSuccessAction {
    static readonly type = UserActionTypes.GET_CONTRACTS_SUCCESS;
    constructor(public contracts: Contract[]) { }
}

export class GetContractsErrorAction {
    static readonly type = UserActionTypes.GET_CONTRACT_ERROR;
    constructor(public error: string) { }
}


export class UpdateProfileRequestAction {
    static readonly type = UserActionTypes.UPDATE_PROFILE_REQUEST;
    constructor(public user: Partial<User>) { }
}

export class UpdateProfileSuccessAction {
    static readonly type = UserActionTypes.UPDATE_PROFILE_SUCCESS;
    constructor(public user: User) { }
}

export class UpdateProfileErrorAction {
    static readonly type = UserActionTypes.UPDATE_PROFILE_ERROR;
    constructor(public error: string) { }
}

export class UpdatePasswordRequestAction {
    static readonly type = UserActionTypes.UPDATE_PASSWORD_REQUEST;
    constructor(public payload: { password: string, newPassword: string }) { }
}

export class UpdatePasswordSuccessAction {
    static readonly type = UserActionTypes.UPDATE_PASSWORD_SUCCESS;
}

export class UpdatePasswordErrorAction {
    static readonly type = UserActionTypes.UPDATE_PASSWORD_ERROR;
    constructor(public error: string) { }
}

export class GetBankCardRequestAction {
    static readonly type = UserActionTypes.GET_BANK_CARD_REQUEST;
}

export class GetBankCardSuccessAction {
    static readonly type = UserActionTypes.GET_BANK_CARD_SUCCESS;
    constructor(public card: any) { }
}

export class GetBankCardErrorAction {
    static readonly type = UserActionTypes.GET_BANK_CARD_ERROR;
    constructor(public error: string) { }
}

export class UpdateBankCardRequestAction {
    static readonly type = UserActionTypes.UPDATE_BANK_CARD_REQUEST;
    constructor(public card: StripeElementsCard) { }
}

export class UpdateBankCardSuccessAction {
    static readonly type = UserActionTypes.UPDATE_BANK_CARD_SUCCESS;
    constructor(public card: any) { }
}

export class UpdateBankCardErrorAction {
    static readonly type = UserActionTypes.UPDATE_BANK_CARD_ERROR;
    constructor(public error: string) { }
}

export class UpdateLanguageRequestAction {
    static readonly type = UserActionTypes.UPDATE_LANGUAGE_REQUEST;
    constructor(public language: Language) { }
}

export class UpdateLanguageSuccessAction {
    static readonly type = UserActionTypes.UPDATE_LANGUAGE_SUCCESS;
}

export class UpdateLanguageErrorAction {
    static readonly type = UserActionTypes.UPDATE_LANGUAGE_ERROR;
    constructor(public error: string) { }
}

export class CreateContractRequestAction {
    static readonly type = UserActionTypes.CREATE_CONTRACT_REQUEST;
    constructor(public payload: { group: Group, subscriptionPlan: SubscriptionPlan }) { }
}

export class CreateContractSuccessAction {
    static readonly type = UserActionTypes.CREATE_CONTRACT_SUCCESS;
    constructor(public contract: Contract) { }
}

export class CreateContractErrorAction {
    static readonly type = UserActionTypes.CREATE_CONTRACT_ERROR;
    constructor(public error: string) { }
}
