import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { switchMap, catchError } from 'rxjs/operators';

import { SubscriptionPlan } from '../../models';
import {
    GetSubscriptionPlanRequestAction,
    GetSubscriptionPlanSuccessAction,
    GetSubscriptionPlanErrorAction,
    SetSelectedSubscriptionPlanAction
} from './subscription-plan.actions';

import { SubscriptionPlanService } from '../../http';

export interface SubscriptionPlanStateModel {
    entities: SubscriptionPlan[];
    selected: SubscriptionPlan | null;
    isLoading: boolean;
    error?: string;
}

export const subscriptionPlanDefaultState: SubscriptionPlanStateModel = {
    entities: [],
    selected: null,
    isLoading: false,
};

/**
 * Subscription plan state - Stored state of app subscriptions plan
 * ? TODO Should refactor in group
 */
@State<SubscriptionPlanStateModel>({
    name: 'subscriptionPlan',
    defaults: subscriptionPlanDefaultState
})
export class SubscriptionPlanState {

    constructor(private subscriptionPlanService: SubscriptionPlanService) { }

    // SELECT SUBSCRIPTION PLAN ENTITIES
    @Selector()
    static entities(state: SubscriptionPlanStateModel) {
        return state.entities;
    }
    // SELECTE SELECTED SUBSCRIPTION PLAN
    @Selector()
    static selected(state: SubscriptionPlanStateModel) {
        return state.selected;
    }
    /**
     * Select if plan is loading
     */
    @Selector()
    static isLoading(state: SubscriptionPlanStateModel): boolean {
        return state.isLoading;
    }
    // GET SUBSCRIPTION PLAN REQUEST
    @Action(GetSubscriptionPlanRequestAction)
    getSubscriptionPlanRequest(
        ctx: StateContext<SubscriptionPlanStateModel>,
        action: GetSubscriptionPlanRequestAction
    ) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: true
        });
        return this.subscriptionPlanService.getSubscriptionPlansByGroupId(action.group.id).pipe(
            switchMap((subscriptionPlans: SubscriptionPlan[]) =>
                ctx.dispatch(new GetSubscriptionPlanSuccessAction(subscriptionPlans))),
            catchError(err => ctx.dispatch(new GetSubscriptionPlanErrorAction(err.message)))
        );
    }
    // GET SUBSCRIPTION PLAN SUCCESS
    @Action(GetSubscriptionPlanSuccessAction)
    getSubscriptionPlanSuccess(
        ctx: StateContext<SubscriptionPlanStateModel>,
        action: GetSubscriptionPlanSuccessAction
    ) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            entities: action.subscriptionPlans,
            isLoading: false
        });
    }
    // GET SUBSCRIPTION PLAN ERROR
    @Action(GetSubscriptionPlanErrorAction)
    getSubscriptionPlanError(
        ctx: StateContext<SubscriptionPlanStateModel>,
        action: GetSubscriptionPlanErrorAction
    ) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoading: false,
            error: action.error
        });
    }
    // SET SELECTED SUBSCRIPTION PLAN
    @Action(SetSelectedSubscriptionPlanAction)
    SetSelectedSubscriptionPlan(ctx: StateContext<SubscriptionPlanStateModel>, action: SetSelectedSubscriptionPlanAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selected: action.subscriptionPlan
        });
    }
}
