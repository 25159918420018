import { Component, Input } from '@angular/core';

import { Alignment } from 'src/app/core/types';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent  {

  @Input()
  back: boolean;

  @Input()
  icon: string;

  @Input()
  titleOne: string;

  @Input()
  titleTwo: string;

  @Input()
  description: string;

  @Input()
  separator: boolean;

  @Input()
  stepper: boolean;

  @Input()
  step: number;

  @Input()
  range: number;

  @Input()
  align: Alignment = 'center';
}
