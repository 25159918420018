import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

/**
 * Api service - Base service to request vls api
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.api.serverUrl + '/' + environment.api.version;
  private headers = new HttpHeaders().set('Content-type', 'application/json');

  constructor(private httpClient: HttpClient) { }
  /**
   * GET
   * @param path endpoint path
   * @param params http params
   */
  get(path: string, params?: HttpParams): Observable<any> {
    return this.httpClient.get(this.apiUrl + path, { params });
  }
  /**
   * POST
   * @param path endpoint path
   * @param body object body
   */
  post(path: string, body: object | string = {}): Observable<any> {
    return this.httpClient.post(this.apiUrl + path, body, { headers: this.headers });
  }
  /**
   * PATCH
   * @param path enpoint path
   * @param body object body
   */
  patch(path: string, body: object | string = {}): Observable<any> {
    return this.httpClient.patch(this.apiUrl + path, body, { headers: this.headers });
  }
  /**
   * PUT
   * @param path endpoint path
   * @param body object body
   */
  put(path: string, body: object = {}): Observable<any> {
    return this.httpClient.put(this.apiUrl + path, body, { headers: this.headers });
  }
  /**
   * DELETE
   * @param path endpoint path
   * @param params http params
   */
  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.delete(this.apiUrl + path, { params });
  }

}
