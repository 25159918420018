import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Icon service - Register all custom icon for angular material
 */
@Injectable({
  providedIn: 'root'
})
export class IconService {
  iconFolderPath = '../../../assets/icons';

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public init() {
    this.addIcons([
      'bubble',
      'burger',
      'edit',
      'bike',
      'profile',
      'money',
      'email',
      'email-valid',
      'key',
      'not-found',
      'wallet',
      'right-arrow',
      'left-arrow',
      'pen',
      'phone',
      'chrono',
      'time',
      'paper',
      'folder-lock',
      'god',
      'visa',
      'mastercard',
      'unknown',
      'pin',
      'plus',
      'currency-euro'
    ]);
  }

  public addIcons(icons: string[]): void {
    icons.forEach(icon => this.addIcon(icon));
  }

  public addIcon(name: string): IconService {
    this.iconRegistry.addSvgIcon(
      name,
      this.sanitizeUrl(`${this.iconFolderPath}/${name}.svg`)
    );
    return this;
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
